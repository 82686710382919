import { SetupContext } from "@vue/composition-api";

export default function useAbstract({ root }: Partial<SetupContext>) {
  const getTypeName = (type: string) => {
    switch (type) {
      case "presentation":
        return root?.$tc("panel.abstract.type.oralPresentation");
      case "symposium":
        return root?.$tc("panel.abstract.type.symposium");
      case "poster":
        return root?.$tc("panel.abstract.type.poster");
      default:
        return "";
    }
  };

  const typeItems = [
    {
      value: "presentation",
      label: root?.$tc("panel.abstract.type.oralPresentation"),
    },
    {
      value: "symposium",
      label: root?.$tc("panel.abstract.type.symposium"),
    },
    {
      value: "poster",
      label: root?.$tc("panel.abstract.type.poster"),
    },
  ];

  const getStatusName = (status: string) => {
    switch (status) {
      case "accepted":
        return root?.$tc("panel.abstract.status.accepted");
      case "rejected":
        return root?.$tc("panel.abstract.status.rejected");
      case "reviewed":
        return root?.$tc("panel.abstract.status.reviewed");
      case "designated":
        return root?.$tc("panel.abstract.status.designated");
      case "new":
        return root?.$tc("panel.abstract.status.new");
      case "init accept":
        return root?.$tc("panel.abstract.status.conditionalAccept");
      default:
        return "";
    }
  };

  const statusItems = [
    { value: "accepted", name: root?.$tc("panel.abstract.status.accepted") },
    { value: "rejected", name: root?.$tc("panel.abstract.status.rejected") },
    { value: "reviewed", name: root?.$tc("panel.abstract.status.reviewed") },
    {
      value: "designated",
      name: root?.$tc("panel.abstract.status.designated"),
    },
    { value: "new", name: root?.$tc("panel.abstract.status.new") },
    {
      value: "init accept",
      name: root?.$tc("panel.abstract.status.conditionalAccept"),
    },
  ];

  return {
    getTypeName,
    typeItems,
    getStatusName,
    statusItems,
  };
}
