









































































































import useAbstract from "@/use/abstract";
import { defineComponent, reactive, watch } from "@vue/composition-api";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    topics: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, { root }) {
    const { typeItems } = useAbstract({ root });
    const state = reactive({
      rating: {
        min: "",
        max: "",
      },
      createdAt: {
        min: "",
        max: "",
      },
      updatedAt: {
        min: "",
        max: "",
      },
    });

    const rating = [
      { value: "0", label: "0%" },
      { value: "0.1", label: "10%" },
      { value: "0.2", label: "20%" },
      { value: "0.3", label: "30%" },
      { value: "0.4", label: "40%" },
      { value: "0.5", label: "50%" },
      { value: "0.6", label: "60%" },
      { value: "0.7", label: "70%" },
      { value: "0.8", label: "80%" },
      { value: "0.9", label: "90%" },
      { value: "1", label: "100%" },
    ];

    watch(
      () => state.rating,
      () => {
        if (state.rating.min || state.rating.max) {
          props.value.abstractRating.values = [
            state.rating.min ? state.rating.min : "0",
            state.rating.max ? state.rating.max : "1",
          ];
        } else props.value.abstractRating.values = [];
      },
      { deep: true }
    );

    watch(
      () => state.createdAt,
      () => {
        if (state.createdAt.min || state.createdAt.max) {
          props.value.abstractCreatedAt.values = [
            state.createdAt.min ? state.createdAt.min : "1980-08-19T09:55",
            state.createdAt.max ? state.createdAt.max : "2050-08-19T09:55",
          ];
        } else props.value.abstractCreatedAt.values = [];
      },
      { deep: true }
    );

    watch(
      () => state.updatedAt,
      () => {
        if (state.updatedAt.min || state.updatedAt.max) {
          props.value.abstractUpdateddAt.values = [
            state.updatedAt.min ? state.updatedAt.min : "1980-08-19T09:55",
            state.updatedAt.max ? state.updatedAt.max : "2050-08-19T09:55",
          ];
        } else props.value.abstractUpdateddAt.values = [];
      },
      { deep: true }
    );

    return { typeItems, state, rating };
  },
});
